import { useEffect, useState } from "react";

import { AutoComplete } from "primereact/autocomplete";

import { JobLocation } from "./jobLocation";
import { useWindowWidth } from '~/components/windowWidth';

export const RadiusData = [
  { value: "5" },
  { value: "10" },
  { value: "20" },
  { value: "30" },
  { value: "40" },
  { value: "50" },
  { value: "60" },
];

export const DummyComponentFallBack = () => <>Loading...</>;

export const getCategories = async () => {
  const realJobsAPI = "https://webapi.theqbis.com/api/CommonAPI/GetCategoryList";
  const environment =
    process.env.NODE_ENV === "development"
      ? realJobsAPI
      : realJobsAPI;
  return fetch(environment, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      'Cache-Control': 'no-cache'
    },
  }).then((res) => res.json());
};


export function Search({ googleApiKey }: { googleApiKey: string; }) {
  const [job, setJob]: any = useState("");
  const [jobsData, setJobsData] = useState<any>([]);
  const [categories, setCategories] = useState([]);

  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 768;

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getCategories();
      if (data?.length) {
        setCategories(data);
      } else {
        setCategories([]);
      }
    };

    fetchData();
  }, []);


  const searchJobs = (event: any) => {
    const data = categories.filter((x: any) =>
      x.categoryName.toLowerCase().includes(event.query.toLowerCase())
    );

    if (data?.length) {
      setJobsData(data);
      return;
    }

    setJobsData([]);
  };

  return (
    <div
      data-testid="SAUK-mobile-search-toolbar"
      className="mx-auto md:px-0 md:max-w-xl"
      style={{
        position: 'absolute',
        zIndex: 9,
        top: isMobile ? '100px' : '200px',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
      }}
    >
      <div className="z-900 items-center justify-center my-5 bg-white shadow-lg rounded-3xl border-1 w-full flex">
        <div className="w-1/2 p-0">
          <AutoComplete
            value={job}
            suggestions={jobsData}
            completeMethod={searchJobs}
            onChange={(e: any) => setJob(e.value)}
            className="form-control border-r-4 ml-2"
            delay={500}
            placeholder="Search For Jobs..."
            field="categoryName"
            emptyMessage="No data found"
          />
        </div>
        <div className="w-1/2 pr-2 ml-2">
          <JobLocation googleApiKey={googleApiKey} jobTitle={job.categoryName} />
        </div>
      </div>
    </div>
  );
}


