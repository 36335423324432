import { useEffect, useRef, useState } from "react";
import { Link } from "@remix-run/react";
import useTimeAgo from "~/modules/queries/hooks/useJobTime";
import { generateSalaryString } from "~/components/salaryString/salaryStringFormat";
import { NewJobCardSkeleton } from "~/components/loader/skeletonLoader";
import parse from "html-react-parser";  

const NewJobCard = ({
  jobid,
  jobUrl,
  hidden,
  JobData,
  shareUrl,
  ...props
}: any) => {
  const timeAgo = useTimeAgo(props.createdDate);
  const [isCopied, setIsCopied] = useState(false);
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState<any>({});
  const ID = props?.clientJobDescriptionId
    ? props?.clientJobDescriptionId
    : jobid;
  const handleShareClick = () => {
    const copyUrl = `${shareUrl}/jobs/${encodeURIComponent(
      formatText(data.categoryName + data.subject)
    )}?id=${ID}`;
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(copyUrl)
        .then(() => {
          setIsCopied(true);
          setTimeout(() => setIsCopied(false), 10000);
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    } else {
      console.warn("Clipboard not supported");
    }
  };

  const formatText = (text = "") =>
    text
      .trim()
      .toLowerCase()
      .replace(/[!@#$%^&*(),-/]/g, "")
      .replace(/\s+/g, "-");

  useEffect(() => {
    const fetchJobData = async () => {
      try {
        setLoading(true);
        const ID = props?.clientJobDescriptionId
          ? props?.clientJobDescriptionId
          : jobid;
        const response = await fetch(
          `https://webapi.theqbis.com/api/ClientLiveJob/GetClientLiveJobDetailsById?Id=${ID}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const res = await response.json();
        if (res?.success) {
          setData(res.data);
        } else {
          console.error(
            "Failed to fetch job data:",
            res?.message || "Unknown error"
          );
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching job data:", error);
      }
    };

    fetchJobData();
  }, [jobid]);
  return (
    <>
      {loading ? (
        <NewJobCardSkeleton />
      ) : (
        <div className="">
          {data ? (
            <div
              className={` ${
                hidden ? "min-w-[288px] min-h-[202px]  md:min-w-full" : ""
              } flex flex-col justify-between h-full  border rounded-md border-staff-420   dark:bg-white  p-5 `}
            >
              <div>
                <div
                  className={`text-xl flex justify-between cursor-pointer ${
                    props.clientJobDescriptionId ? "underline" : ""
                  }`}
                >
                  {props.clientJobDescriptionId ? (
                    <Link
                      rel="canonical"
                      to={`/jobs/${encodeURIComponent(
                        props.clientJobDescriptionName &&
                          formatText(props.clientJobDescriptionName)
                      )}?id=${props.clientJobDescriptionId}`}
                      className=" text-xl cursor-pointer text-base font-semibold text-staff-300 font-SourceSans"
                    >
                      {props.categoryName +
                        (props.webAdvertSubject?.length > 1
                          ? ` - ${props.webAdvertSubject}`
                          : "")}
                    </Link>
                  ) : (
                    <div className="text-xl flex justify-between cursor-pointer">
                      {data.categoryName +
                        (data.subject?.length > 1 ? ` - ${data.subject}` : "")}
                    </div>
                  )}
                </div>

                <div className="flex justify-start my-4 space-x-3">
                  <div className="flex space-x-2 place-content-start ">
                    <svg
                      width={22}
                      height={22}
                      viewBox="0 0 13 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="flex-shrink-0"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.17399 14.832C6.19538 14.8444 6.2122 14.854 6.22411 14.8607L6.24389 14.8717C6.40175 14.9584 6.59758 14.9577 6.7556 14.872L6.77589 14.8607C6.7878 14.854 6.80462 14.8444 6.82601 14.832C6.8688 14.8072 6.92994 14.771 7.00681 14.7237C7.1605 14.6291 7.37745 14.4895 7.6366 14.3065C8.15402 13.9409 8.84441 13.3986 9.5363 12.6902C10.9134 11.2803 12.3438 9.16186 12.3438 6.4375C12.3438 3.21009 9.72741 0.59375 6.5 0.59375C3.27259 0.59375 0.65625 3.21009 0.65625 6.4375C0.65625 9.16186 2.0866 11.2803 3.4637 12.6902C4.1556 13.3986 4.84598 13.9409 5.3634 14.3065C5.62255 14.4895 5.8395 14.6291 5.99319 14.7237C6.07006 14.771 6.1312 14.8072 6.17399 14.832ZM6.5 8.5625C7.6736 8.5625 8.625 7.6111 8.625 6.4375C8.625 5.2639 7.6736 4.3125 6.5 4.3125C5.3264 4.3125 4.375 5.2639 4.375 6.4375C4.375 7.6111 5.3264 8.5625 6.5 8.5625Z"
                        fill="#031A38"
                      />
                    </svg>
                    <span className="text-xl font-normal text-staff-420 font-Gilroy">
                      {data.location}
                    </span>
                  </div>
                  <div className="flex space-x-2 place-content-start ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 24 24"
                      className="flex-shrink-0"
                    >
                      <path
                        d="M7.5 6.5C7.5 8.981 9.519 11 12 11s4.5-2.019 4.5-4.5S14.481 2 12 2 7.5 4.019 7.5 6.5zM20 21h1v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h17z"
                        fill="#031A38"
                      ></path>
                    </svg>
                    <span className="text-xl font-normal text-staff-420 font-Gilroy">
                      {data.categoryName}
                    </span>
                  </div>
                </div>
                <div className="flex justify-start my-4 space-x-3">
                  <div className="flex space-x-2 place-content-start ">
                    <div className="flex-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="#031A38"
                          d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm261.5-96c-16.8 0-30.4 13.7-30.3 30.5l.2 33.5 40.5 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-40.3 0 .1 9.7c.1 18.6-3.1 37-9.6 54.3L328 320c13.3 0 24 10.7 24 24s-10.7 24-24 24l-144 0c-8.4 0-16.1-4.4-20.5-11.5s-4.7-16-.8-23.5l9.6-18.6c7.7-14.9 11.6-31.5 11.5-48.3l-.1-10.1-7.7 0c-8.8 0-16-7.2-16-16s7.2-16 16-16l7.5 0-.2-33.1c-.3-43.5 34.8-78.9 78.3-78.9 10 0 19.8 1.9 29.1 5.6l30.3 12.1c12.3 4.9 18.3 18.9 13.4 31.2s-18.9 18.3-31.2 13.4l-30.3-12.1c-3.6-1.4-7.4-2.2-11.3-2.2z"
                        ></path>
                      </svg>
                    </div>
                    <span className="text-xl font-normal text-staff-420 font-Gilroy">
                      {generateSalaryString(data)}
                    </span>
                  </div>
                </div>
                <div className="flex justify-start my-4 space-x-3">
                  <div className="flex space-x-2 place-content-start ">
                    <div className="flex-shrink-0">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill="#031A38"
                          d="M459.26,96,328,225.7V96H293.475L168,223.267V16H16V496H496V96ZM464,464H48V48h88V264h36.778L296,139.018V264h38.764L464,136.3Z"
                        />
                        <polygon
                          fill="#031A38"
                          points="136 328 136 336 168 336 168 328 168 304 136 304 136 328"
                        />
                        <rect
                          width="32"
                          height="32"
                          x="136"
                          y="376"
                          fill="#031A38"
                        />
                        <polygon
                          fill="#031A38"
                          points="216 328 216 336 248 336 248 328 248 304 216 304 216 328"
                        />
                        <rect
                          width="32"
                          height="32"
                          x="216"
                          y="376"
                          fill="#031A38"
                        />
                        <polygon
                          fill="#031A38"
                          points="296 328 296 336 328 336 328 328 328 304 296 304 296 328"
                        />
                        <rect
                          width="32"
                          height="32"
                          x="296"
                          y="376"
                          fill="#031A38"
                        />
                        <rect
                          width="32"
                          height="32"
                          x="376"
                          y="304"
                          fill="#031A38"
                        />
                        <rect
                          width="32"
                          height="32"
                          x="376"
                          y="376"
                          fill="#031A38"
                        />
                      </svg>
                    </div>
                    <span className="text-xl font-normal text-staff-420 font-Gilroy">
                      {data.industry}
                    </span>
                  </div>
                </div>
                <div className="flex justify-start my-4 space-x-3">
                  <div className="flex space-x-2 place-content-start ">
                    <div className="flex-shrink-0">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill="#031A38"
                          d="M19,6H16V5a2,2,0,0,0-2-2H10A2,2,0,0,0,8,5V6H5A3,3,0,0,0,2,9v9a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V9A3,3,0,0,0,19,6ZM10,5h4V6H10ZM20,18a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V12.39L8.68,14A1.19,1.19,0,0,0,9,14h6a1.19,1.19,0,0,0,.32-.05L20,12.39Zm0-7.72L14.84,12H9.16L4,10.28V9A1,1,0,0,1,5,8H19a1,1,0,0,1,1,1Z"
                        />
                      </svg>
                    </div>
                    <span className="text-xl font-normal text-staff-420 font-Gilroy">
                      {data.type || "N/A"}
                    </span>
                  </div>
                </div>
                <div className="flex gap-5 justify-end mb-3">
                  <a
                    href={jobUrl}
                    className={`${
                      hidden ? "w-full text-center mt-5" : "w-max "
                    } font-normal text-white font-SourceSans  bg-staff-400 hover:bg-staff-420 rounded-3xl px-5 py-2 block`}
                  >
                    Apply for this job
                  </a>
                  <button
                    onClick={handleShareClick}
                    className={`${
                      hidden ? "w-full text-center mt-5" : "w-max "
                    } font-normal text-white font-SourceSans bg-staff-400 hover:bg-staff-420 rounded-3xl px-5 py-2 block`}
                  >
                    Share
                  </button>

                  {isCopied && (
                    <span className="text-green-500 text-sm mt-2">
                      Copied to clipboard!
                    </span>
                  )}
                </div>
                <div className="max-h-[500px] overflow-y-auto">
                  <div className="mb-3">
                    <h1 className="text-2xl mb-3 text-[#1bafe3]">
                      Job Description
                    </h1>
                    <div style={{ paddingLeft: "1.5rem" }}>
                    <p className="font-Inter">{parse(data.jobDescription) || "N/A"}</p>
                    </div>
                    </div>
                  <div className="border rounded-lg overflow-hidden shadow-sm mb-3">
                    <div className="bg-gray-200 p-3 font-semibold text-2xl text-[#1bafe3]">
                      Requirements
                    </div>
                    <div className="p-4">
                      <ul className="font-Inter list-disc list-inside space-y-2 text-gray-800">
                        {data.requirements?.map((rate: any, index: any) => (
                          <li className="list-none" key={index}>
                            {rate}
                          </li>
                        )) || "N/A"}
                      </ul>
                    </div>
                  </div>
                  <div className="mb-3">
                    <h1 className="text-2xl mb-3 text-[#1bafe3]">
                      Key Responsibilities
                    </h1>
                    <ul className="font-Inter space-y-2">
                      {data.keyResponsibilities?.map(
                        (rate: any, index: any) => (
                          <li className="list-none" key={index}>
                            {rate}
                          </li>
                        )
                      ) || "N/A"}
                    </ul>
                  </div>
                  <div className="mb-3">
                    <h1 className="text-2xl mb-3 text-[#1bafe3]">
                      Salary Package
                    </h1>
                    <ul className="font-Inter space-y-2">
                      {data.payeRates?.map((rate: any, index: any) => (
                        <li className="list-none" key={index}>
                          {rate}
                        </li>
                      )) || "N/A"}
                    </ul>
                  </div>
                  <div className="border rounded-lg overflow-hidden shadow-sm mb-3">
                    <div className="bg-gray-200 p-3 font-semibold text-2xl text-[#1bafe3]">
                      Benefits
                    </div>
                    <div className="p-4">
                      <ul className="font-Inter list-disc list-inside space-y-2 text-gray-800">
                        {data.benefits?.map((rate: any, index: any) => (
                          <li className="list-none" key={index}>
                            {rate}
                          </li>
                        )) || "N/A"}
                      </ul>
                    </div>
                  </div>
                  <div className="mb-3">
                    <h1 className="text-2xl mb-3 text-[#1bafe3]">
                      How to apply
                    </h1>
                    <div style={{ paddingLeft: "1.5rem" }}>
                    <p className="font-Inter">{parse(data.howToApply) || "N/A"}</p>
                    </div>
                  </div>
                  <div className="mb-3">
                    <h1 className="text-2xl mb-3 text-[#1bafe3]">
                      Our Commitment
                    </h1>
                    <div style={{ paddingLeft: "1.5rem" }}>
                    <p className="font-Inter">{parse(data.ourCommitment) || "N/A"}</p>
                    </div>
                  </div>
                  <div className="mb-3">
                    <h1 className="text-2xl mb-3 text-[#1bafe3]">About Us</h1>
                    <div style={{ paddingLeft: "1.5rem" }}>
                    <p className="font-Inter">{parse(data.aboutUs) || "N/A"}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <a
                  href={jobUrl}
                  className={`${
                    hidden ? "w-full text-center mt-5" : "w-max "
                  } font-normal text-white font-SourceSans  bg-staff-400 hover:bg-staff-420 rounded-3xl px-5 py-2 block`}
                >
                  Apply for this job
                </a>{" "}
              </div>
              <div className="flex-col justify-between mt-4 ">
                <span
                  className={` ${
                    hidden ? "hidden" : "null"
                  } text-staff-170   text-sm whitespace-nowrap  font-SourceSans font-normal flex mb-4`}
                >
                  {timeAgo}
                </span>
                <></>
              </div>
            </div>
          ) : (
            <p className="text-center text-gray-500">No data found</p>
          )}
        </div>
      )}
    </>
  );
};

export default NewJobCard;
