export const formatSalary = (amount: number) => new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}).format(amount);

export function generateSalaryString({
  salaryTypeValue,
  salaryPeriodValue,
  basicFromSalary,
  basicToSalary,
  basicSalary,
}: {
  salaryTypeValue: string;
  salaryPeriodValue: string;
  basicFromSalary: number;
  basicToSalary: number;
  basicSalary: number;
}): string {
  if (!salaryTypeValue || !salaryPeriodValue) return "N/A";

  switch (salaryTypeValue) {
    case "Up to":
      return `Up To ${formatSalary(basicSalary)}  ${salaryPeriodValue}`;

    case "Exactly":
      return `${formatSalary(basicSalary)}  ${salaryPeriodValue}`;

    case "Range":
      return `${formatSalary(
        basicFromSalary
      )}  ${salaryPeriodValue} to ${formatSalary(
        basicToSalary
      )}  ${salaryPeriodValue}`;

    default:
      return "N/A";
  }
}

export function generateSalaryStringForInternalJobs({
  salaryType,
  salaryPeriod,
  toSalary,
  salary,
}: {
  salaryType: string;
  salaryPeriod: string;
  toSalary: number;
  salary: number;
}): string {
  if (!salaryType || !salaryPeriod) return "N/A";

  switch (salaryType) {
    case "Upto":
      return `Up to ${formatSalary(salary)}  ${salaryPeriod}`;

    case "Exactly":
      return `${formatSalary(salary)}  ${salaryPeriod}`;

    case "Range":
      return `${formatSalary(salary)} to ${formatSalary(
        toSalary
      )}  ${salaryPeriod}`;

    default:
      return "N/A";
  }
}