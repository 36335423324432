import { useEffect, useState } from "react";
import { Popover } from "@headlessui/react";
import { Link, useLocation } from "@remix-run/react";
import clsx from "clsx";
import type { GlobalData, MarkerPoint, NavRequest } from "~/types";

import { NavigationPopup } from "./NavigationPopup";
import { ContactIcon } from "../icons/contact";
import { Facebook } from "../icons/facebook";
import { Linkedin } from "../icons/linkedinLogo";
import { Instagram } from "../icons/lnstagram";
import { BurgerButton } from "../organisms/BurgerBtn";
import { DialogModel } from "../organisms/dialogModel/DialogModel";
import { LogoImage } from "../organisms/Logo";
import { MultipleContactPopoover } from "../organisms/MultipleContactPopover/MultipleContactPopoover";
import { Switcher } from "../organisms/Switch";
import LocationTooltip from "../TooltipLocation";

export function Header({
  nav,
  routePath,
  globalData,
  markerPoints,
}: {
  nav: NavRequest[];
  routePath: string;
  globalData: GlobalData;
  markerPoints: MarkerPoint[];
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [contactPanel, setContactPanel] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();
  const currentRoute = location.pathname;
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 50) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <Popover
    className={clsx(
      "sticky md:sticky top-0 z-40 md:z-[9999] w-full bg-white dark:bg-staff-300 bg-opacity-20 dark:bg-opacity-20 backdrop-blur",
      // isVisible &&
      //   " bg-white dark:bg-staff-300 bg-opacity-20 dark:bg-opacity-20 backdrop-blur"
    )}
  >
      <div className="px-4 mx-auto max-w-7xl ">
        <div className="container mx-auto ">
          <div className="flex items-center justify-between py-3 md:py-6 md:justify-start md:space-x-10">
            <div className="flex items-center justify-between w-full space-x-7 ">
              <div className="flex items-center justify-between w-full space-x-8 ">
                <div className="flex items-center justify-start space-x-8">
                  <Link  prefetch="render" to="/">
                    <LogoImage className={"w-8 h-8 md:w-20 md:h-20"} />
                  </Link>
                  <div className="hidden md:block">
                    <div>
                      <ul
                        data-testid="sauk-header-nav"
                        className="flex items-center justify-start space-x-5 text-2xl font-extrabold whitespace-nowrap font-Gilroy text-staff-300 dark:text-white"
                      >
                        {nav &&
                          nav?.map((nav) => {
                            const isActive = currentRoute
                              .replace("/", "")
                              .startsWith(nav.path);
                            const textColor = isActive
                              ? "text-staff-200"
                              : "text-black";
                            const darkTextColor = isActive
                              ? "dark:text-staff-200"
                              : "dark:text-white";

                            return (
                              <div key={nav.id}>
                                <Link
                                  rel="canonical"
                                  prefetch="render"
                                  className={`${textColor} ${darkTextColor} hover:text-staff-200 dark:hover:text-staff-200`}
                                  to={nav.path}
                                >
                                  {nav.title}
                                </Link>
                              </div>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="items-center justify-around hidden space-x-8 md:flex">
                  <div
                    data-testid="sauk-header-icons"
                    className="items-center justify-end hidden space-x-5 lg:flex "
                  >
                    {globalData?.data?.attributes?.SocialLinks[0]?.Link != null ? (
                      <Facebook
                        url={globalData.data.attributes.SocialLinks[0]?.Link}
                        loc="header"
                      />
                    ) : (
                      ""
                    )}
                    {globalData.data.attributes.SocialLinks[1]?.Link != null ? (
                      <Instagram
                        url={globalData.data.attributes.SocialLinks[1]?.Link}
                        loc="header"
                      />
                    ) : (
                      ""
                    )}
                    {globalData.data.attributes.SocialLinks[2]?.Link != null ? (
                      <Linkedin
                        url={globalData.data.attributes.SocialLinks[2]?.Link}
                        loc="header"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  {globalData.data.attributes.SocialLinks[3]?.Link != null && (
                    <div
                      className="flex items-center justify-center space-x-2"
                      onMouseEnter={() => {
                        setContactPanel(true);
                      }}
                      onMouseLeave={() => {
                        setContactPanel(false);
                      }}
                      onClick={() => {
                        setContactPanel(true);
                      }}
                    >
                      <button className="flex items-center justify-center space-x-2">
                        <ContactIcon position="top" />

                        {/* <MultipleContactPopoover
                                                    markerPoints={markerPoints}
                                                    setIsOpen={setContactPanel}
                                                    isOpen={contactPanel}
                                                    closeModal={() => setContactPanel(false)}
                                                /> */}
                        {/* <span className="text-2xl font-bold whitespace-nowrap font-SourceSans text-staff-300 dark:text-white">
                                                    {globalData.data.attributes.SocialLinks[3].Link}
                                                </span> */}
                      </button>
                    </div>
                  )}
                  <div>
                    <Switcher />
                  </div>
                </div>
              </div>
            </div>
            <BurgerButton
              data-testid="sauk-burger-button"
              setIsOpen={setIsOpen}
              isOpen={isOpen}
            />
          </div>

          <DialogModel
            data-testid="SAUK-mobile-search-dialog-model"
            className="absolute top-0 left-0"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          >
            <NavigationPopup
              routePath={routePath}
              setIsOpen={setIsOpen}
              nav={nav}
            />
          </DialogModel>
        </div>

        {contactPanel && (
          <LocationTooltip
            markerPoints={markerPoints}
            onClose={() => setContactPanel(false)}
          />
        )}
      </div>
    </Popover>
  );
}
