export const RadiusData = [
  { value: 5, label: 5 },
  { value: 10, label: 10 },
  { value: 15, label: 15 },
  { value: 20, label: 20 },
  { value: 25, label: 25 },
  { value: 30, label: 30 },
  { value: 35, label: 35 },
];

export const IndustryData = [
  {
    value: "Industrial",
    label: "Industrial",
  },
  {
    value: "Driving",
    label: "Driving",
  },
  {
    value: "Internal",
    label: "Internal",
  },
];

export const TypeData = [
  { value: "Temp", label: "Temp" },
  { value: "Temp to perm", label: "Temp to perm" },
  { value: "Ad-hoc", label: "Ad-hoc" },
];

export const InternalRolesTypes = [
  { value: "Part-Time", label: "Part-Time" },
  { value: "Full-Time", label: "Full-Time" },
];
